.logo {
  height: 50px;
  background-position: 50%;
  background-size: cover;
  background-image: url(/logo/qzwy.png);
  margin: 16px;
}
.list-div{
    width: 100%;
}
